body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  transition: background-color 0.3s;
}

*, *::before, *::after {
  box-sizing: border-box;
}

/* Light Mode Styles */
body {
  background-color: #f0f0f0;
  color: #333; 
}

/* Night Mode Styles */
body.night-mode {
  background-color: #333;
  color: #ecf0f1;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  font-size: clamp(1rem, 2vw, 1.2rem);
}