/* Scoped styles for AboutPage */

.about-page-container .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 100%; 
    padding: 0; 
    box-sizing: border-box;
}

.about-page-container .full-width-header {
    width: 100%; 
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
}

.about-page-container #about-header {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: 'Arial', sans-serif;
    padding: 10px 0;
    background-color: #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    transition: background-color 0.3s, color 0.3s;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.about-page-container .top-image {
    display: block;
    width: 40%; 
    height: auto;
    margin: 20px auto;
}

.about-page-container .columns {
    display: flex;
    width: 70%;
    justify-content: space-between;
    margin: 20px auto;
}

.about-page-container .contact-education {
    flex: 1;
    padding-right: 20px;
    max-width: calc(50% - 10px); 
}

.about-page-container .about-text {
    flex: 3;
    padding-left: 20px;
    max-width: calc(50% - 10px); 
}

.about-page-container .resume-button {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.about-page-container .resume-button:hover {
    background-color: #0056b3;
}

.about-page-container #about, .about-page-container .contact-education {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding: 20px;
}

/* Responsive design adjustments */
@media (max-width: 800px) {
    .about-page-container .main-content {
        padding: 20px;
    }

    .about-page-container .columns {
        flex-direction: column;
    }

    .about-page-container .contact-education, .about-page-container .about-text {
        padding-right: 0;
        padding-left: 0;
        margin-bottom: 20px;
        max-width: 100%; 
    }

    .about-page-container .top-image {
        width: 95%;
    }
}

/* Dark mode styles adjusted for correct targeting */
body.night-mode .about-page-container #about-header,
body.night-mode .about-page-container .contact-education,
body.night-mode .about-page-container #about,
body.night-mode .about-page-container .about-text {
    color: #ecf0f1; 
    background-color: #2a2a2a; 
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5); 
}

body.night-mode .about-page-container .resume-button {
    background-color: #0056b3;
    color: #ecf0f1;
}

body.night-mode .about-page-container .resume-button:hover {
    background-color: #003d7a;
}