.contact-page-container {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
}

.contact-page-container form {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border-radius: 8px;
}

.contact-page-container .form-group {
    margin-bottom: 20px;
}

.contact-page-container label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.contact-page-container input[type="text"],
.contact-page-container input[type="email"],
.contact-page-container textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.contact-page-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.contact-page-container button:hover {
    background-color: #0056b3;
}

/* Styling for the heading */
.contact-page-container #contact h1 {
    text-align: center;
    font-size: 2rem; 
    color: #333; 
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: 'Arial', sans-serif;
    padding: 10px 0;
    background-color: #e0e0e0; 
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); 
    transition: background-color 0.3s, color 0.3s;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5); 
}

/* Result message styling */
.contact-page-container #formResult {
    display: none; 
    padding: 20px;
    margin-top: 20px;
    background-color: #e7f3fe;
    border: 1px solid #9abfe6;
    border-radius: 4px;
    color: #31708f;
}

/* Dark mode styles */
body.night-mode .contact-page-container {
    background-color: #333;
    color: #f4f4f4;
}

body.night-mode .contact-page-container form {
    background-color: #222;
    color: #f4f4f4;
}

body.night-mode .contact-page-container label {
    color: #fff;
}

body.night-mode .contact-page-container #contact h1 {
    color: #ecf0f1; 
    background-color: #2a2a2a; 
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5); 
}

/* Keeping buttons blue in dark mode */
body.night-mode .contact-page-container button {
    background-color: #007bff;
    color: white;
}

body.night-mode .contact-page-container button:hover {
    background-color: #0056b3;
}