.sidebar {
    height: 100vh;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    padding-top: 20px;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.theme-switch-wrapper {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ddd;
    transition: background-color 0.3s;
    margin-bottom: 20px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin-top: 80px;
    flex-grow: 1;
}

.sidebar ul li {
    padding: 8px;
    text-align: left;
}

.sidebar a {
    padding: 10px 15px;
    text-decoration: none;
    font-size: 18px;
    color: #333;
    display: flex;
    align-items: center;
    transition: 0.3s;
    border-radius: 20px;
    background-color: #f9f9f9;
}

.sidebar a i {
    margin-right: 30px;
}

.sidebar a:hover {
    color: #007bff;
    background-color: #f0f0f0; 
}

/* Dark mode styles */
body.night-mode .sidebar {
    background-color: #000;
}

body.night-mode .sidebar a {
    color: #ecf0f1;
    background-color: #333;
}

body.night-mode .sidebar a:hover {
    color: #007bff;
    background-color: #444;
}

body.night-mode .sidebar a i {
    color: inherit;
}

@media screen and (max-width: 700px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: fixed;
        bottom: 0;
        left: 0;
        top: auto;
        flex-direction: row;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 10px;
        z-index: 2;
    }
    .sidebar ul {
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .sidebar ul li {
        padding: 8px 10px;
    }
    .sidebar a {
        padding: 5px 10px;
        font-size: 16px;
    }
    .theme-switch-wrapper {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        margin: 0 10px;
    }
}

@media screen and (max-width: 400px) {
    .sidebar a {
        font-size: 14px;
    }
}