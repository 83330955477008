@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
      visibility: visible;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }

.projects-page-container #projects h1 {
    text-align: center;
    font-size: 2rem;
    color: #333; 
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: 'Arial', sans-serif;
    padding: 10px 0;
    background-color: #e0e0e0; 
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); 
    transition: background-color 0.3s, color 0.3s;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5); 
}

.coming-soon-note {
    font-style: italic;
    text-align: center;
    margin-bottom: 20px;
    color: #007bff; 
    background-color: #f8f9fa; 
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
}

.projects-page-container .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 20px;
    padding: 20px;
}


.projects-page-container .project-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 20px;
    animation: slideInFromLeft 0.5s ease-out forwards;
    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
}

.projects-page-container .project-card:nth-child(1) { animation-delay: 0.1s; }
.projects-page-container .project-card:nth-child(2) { animation-delay: 0.2s; }
.projects-page-container .project-card:nth-child(3) { animation-delay: 0.3s; }
.projects-page-container .project-card:nth-child(4) { animation-delay: 0.4s; }
.projects-page-container .project-card:nth-child(5) { animation-delay: 0.5s; }
.projects-page-container .project-card:nth-child(6) { animation-delay: 0.6s; }
.projects-page-container .project-card:nth-child(7) { animation-delay: 0.7s; }

.projects-page-container .project-card .image-container {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.projects-page-container .project-card h2 {
    margin: 0;
    padding: 20px;
    background: #007bff;
    color: #fff;
    text-align: center;
}

.projects-page-container .project-card p, .projects-page-container .project-card ul {
    padding: 0 20px;
    margin: 10px 0;
}

.projects-page-container .technologies-used {
    list-style: none;
    padding: 0;
}

.projects-page-container .technologies-used li {
    background: #eee;
    display: inline-block;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
    font-size: 0.8em;
}

.project-details-note {
    margin-top: 20px;
    padding: 10px;
    background-color: #f8f9fa; 
    border-left: 5px solid #007bff; 
    font-style: italic;
    color: #333; 
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
}

.projects-page-container .project-links {
    margin-top: auto;
    padding: 20px;
    text-align: center;
}

.projects-page-container .project-links a {
    text-decoration: none;
    background: #007bff;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    margin: 5px 5px 5px 0; 
    display: inline-block; 
    transition: background 0.3s ease;
    white-space: nowrap; 
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .projects-page-container .project-links a {
        margin: 5px; 
    }
    .projects-page-container .project-card img {
        height: 150px;
    }
}

/* Dark mode styles adjusted for correct targeting */
body.night-mode .projects-page-container .project-card {
    background: #424242;
    color: #ecf0f1; 
}

body.night-mode .projects-page-container .project-card h2,
body.night-mode .projects-page-container .project-links a {
    color: #ecf0f1;
}

body.night-mode .projects-page-container .technologies-used li {
    background: #333; 
    color: #ecf0f1;
}

body.night-mode .projects-page-container #projects h1 {
    color: #ecf0f1; 
    background-color: #2a2a2a; 
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5); 
}

body.night-mode .project-details-note {
    background-color: #333; 
    color: #ecf0f1; 
    border-left: 5px solid #007bff; 
}