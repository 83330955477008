/* Define color scheme variables */
:root {
    --text-color: #000; 
    --profile-border-color: #000; 
}

/* Adjust variables for night mode */
body.night-mode {
    --text-color: #fff; 
    --profile-border-color: #fff; 
}

/* Scoped styles for HomePage */
.home-page-container main {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding-left: 250px;
    color: var(--text-color); 
}

.home-page-container .text-and-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
}

.home-page-container .profile-picture {
    width: 300px;
    height: 450px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 20px;
    flex: 0 0 auto;
    border: 5px solid var(--profile-border-color); 
}

.home-page-container #home {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px;
}

.home-page-container #home h1, .home-page-container #home p {
    margin: 0;
    padding: 10px 0;
    color: var(--text-color); 
}

/* Styling for the name */
.home-page-container #home h1 {
    font-size: 48px; 
    font-weight: bold; 
    color: var(--text-color);
    margin-bottom: 20px; 
    align-self: center;
}

/* Styling for the profession title */
.home-page-container #home p {
    font-size: 24px; 
    color: var(--text-color); 
    font-style: italic; 
    margin-bottom: 20px; 
}

.home-page-container .social-links {
    display: flex;
    gap: 10px;
    padding: 10px 0;
    align-self: center;
}

.home-page-container .social-links a {
    color: var(--text-color);
    font-size: 24px;
    padding-right: 25px;
    align-self: center;
}